<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-modal v-model="showModal" title="Confirmation" hide-footer>
              <form>
                <div class="form-group row mb-4">
                  <label for="title" class="col-form-label col-lg-2">Plaque</label>
                  <div class="col-lg-7">
                    <input disabled v-model="data.immatriculation" id="immatriculation" name="immatriculation" type="text"
                           class="form-control"   />
                  </div>
                  <div class="col-lg-3">
                    <input disabled v-model="data.paysCode" id="code" name="code" type="text" class="form-control"
                      />
                  </div>

                </div>
                <div class="form-group row mb-4">
                  <label for="title" class="col-form-label col-lg-2">Châssis</label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.numero_chassis" id="chassis" name="chassis" type="text" class="form-control"
                      />
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Marque</label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.marque" id="marque" name="marque" type="text" class="form-control"
                      />
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Modèle</label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.modele" id="modele" name="modele" type="text" class="form-control"
                     />
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Année</label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.annee" type="number" min="1200" max="2999" class="form-control"
                      />
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Couleur</label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.couleur" id="couleur" name="couleur" type="text" class="form-control"
                     />
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="projectdesc" class="col-form-label col-lg-2">Type véhicule</label>
                  <div class="col-lg-10">
                    <multiselect disabled class="test-primary" v-model="data.type_vehicule"
                      :options="[{ value: 'voiture', label: 'voiture' }, { value: 'moto', label: 'moto' }]"></multiselect>
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="title" class="col-form-label col-lg-2">Motif</label>
                  <div class="col-lg-10">
                    <input disabled v-model="data.motif_recherche" id="title" name="title" type="text" class="form-control"
                      />
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="title" class="col-form-label col-lg-2">Dangérosité</label>
                  <div class="col-lg-10">
                    <multiselect disabled class="test-primary" v-model="data.niveau_dangerosite" :options="options"></multiselect>
                  </div>
                </div>
                <div class="form-group row mb-4">
                  <label for="title" class="col-form-label col-lg-2">Conduite à tenir</label>
                  <div class="col-lg-10">
                    <input disabled type="text" class="form-control" v-model="data.conduite_a_tenir">
                  </div>
                </div>
                <div class="row justify-content-end">
                  <div class="col-md-10">
                    <button type="button" @click="showModal = false" class="btn btn-primary">
                      Annuler
                    </button>
                    <button @click="addVehicule()" type="button" class="btn btn-success" style="float: right"
                      :disabled="spanView">
                      <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                     Valider
                    </button>
                  </div>
                </div>
              </form>
            </b-modal>
            <form>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Numéro de plaque</label>
                <div class="col-lg-7">
                  <input v-model="data.immatriculation" id="immatriculation" name="immatriculation" type="text"
                         class="form-control" placeholder="numero immatriculation,  Exemple: AK 7834"   />
                </div>
                <div class="col-lg-3">
                  <input v-model="data.paysCode" id="code" name="code" type="text" class="form-control"
                    placeholder="code pays,  Exemple: TG" />
                </div>

              </div>
              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Numéro de châssis</label>
                <div class="col-lg-10">
                  <input v-model="data.numero_chassis" id="chassis" name="chassis" type="text" class="form-control"
                    placeholder="Renseignez le numéro de la châssis..." />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Marque</label>
                <div class="col-lg-10">
                  <input v-model="data.marque" id="marque" name="marque" type="text" class="form-control"
                    placeholder="Renseignez la marque du véhicule..." />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Modèle</label>
                <div class="col-lg-10">
                  <input v-model="data.modele" id="modele" name="modele" type="text" class="form-control"
                    placeholder="Renseignez le modèle du véhicule..." />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Année</label>
                <div class="col-lg-10">
                  <input v-model="data.annee" type="number" min="1200" max="2999" class="form-control"
                    placeholder="Renseignez l'année du véhicule'..." />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Couleur</label>
                <div class="col-lg-10">
                  <input v-model="data.couleur" id="couleur" name="couleur" type="text" class="form-control"
                    placeholder="Renseignez la couleur du véhicule'..." />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="projectdesc" class="col-form-label col-lg-2">Type de Véhicule</label>
                <div class="col-lg-10">
                  <multiselect class="test-primary" v-model="data.type_vehicule"
                    :options="[{ value: 'voiture', label: 'voiture' }, { value: 'moto', label: 'moto' }]"></multiselect>
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Motif de la recherche</label>
                <div class="col-lg-10">
                  <input v-model="data.motif_recherche" id="title" name="title" type="text" class="form-control"
                    placeholder="Donnez le motif ou raison de la recherche..." />
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Niveau de dangérosité</label>
                <div class="col-lg-10">
                  <multiselect class="test-primary" v-model="data.niveau_dangerosite" :options="options"></multiselect>
                </div>
              </div>

              <div class="form-group row mb-4">
                <label for="title" class="col-form-label col-lg-2">Conduite à tenir</label>
                <div class="col-lg-10">
                  <input type="text" placeholder="Conduite à tenir" class="form-control" v-model="data.conduite_a_tenir">
                </div>
              </div>

              <div class="form-group row mb-4">
                <label class="col-form-label col-lg-2">Fichiers associés</label>
                <div class="col-lg-10">
                  <VueFileAgent :deletable="true" :maxSize="'350MB'" :maxFiles="20" :meta="true" :multiple="true"
                    :uploadUrl="uploadUrl" :accept="'image/*'" v-model="data.photos" />
                </div>
              </div>

              <div class="row justify-content-end">
                <div class="col-md-10">
                  <button type="reset" class="btn btn-primary">
                    Annuler
                  </button>
                  <button @click="submit()" type="button" class="btn btn-success" style="float: right"
                    :disabled="spanView">
                    <span v-if="spanView" class="spinner-border spinner-border-sm"></span>
                    Enregistrer le véhicule
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { reqMethods } from '../../../state/helpers';
import Swal from "sweetalert2";
import { VueFileAgent } from '@boindil/vue-file-agent-next';
import '@boindil/vue-file-agent-next/dist/vue-file-agent-next.css';
import axios from 'axios';

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    VueFileAgent,
  },
  data() {
    return {
      title: "Ajouter un vehicule recherché",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Véhicule recherché",
          active: true,
        },
      ],
      showModal: false,
      options: ["normal", "dangereux", "très dangereux"],
      data: {
        immatriculation: null,
        marque: null,
        modele: null,
        annee: null,
        couleur: null,
        numero_chassis: null,
        type_vehicule: null,
        motif_recherche: null,
        niveau_dangerosite: null,
        conduite_a_tenir: null,
        statut: 'rechercher',
        photos: [],
      },
      spanView: false,
      authError: null,
      response: null,
    };
  },
  methods: {
    ...reqMethods,
    async addVehicule() {
      this.showModal = false;
      this.spanView = true;
      const that = this;
      const formData = new FormData();
      if (this.data.paysCode) {
        formData.append('immatriculation',this.data.immatriculation+" "+this.data.paysCode);
      } else {
        formData.append('immatriculation',this.data.immatriculation+" RT");
      }
      formData.append('marque', this.data.marque);
      formData.append('modele', this.data.modele);
      formData.append('annee', this.data.annee);
      formData.append('couleur', this.data.couleur);
      formData.append('type_vehicule', this.data.type_vehicule);
      formData.append('numero_chassis', this.data.numero_chassis);
      formData.append('motif_recherche', this.data.motif_recherche);
      formData.append('niveau_dangerosite', this.data.niveau_dangerosite);
      formData.append('conduite_a_tenir', this.data.conduite_a_tenir);
      formData.append('statut', this.data.statut);
      for (const photo of this.data.photos) {
        formData.append('photos', photo.file);
      }
      try {
        const response = await axios.post('https://api.alcit.sims-technologie.com/api/v1/vehicule/vehicule/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token'),
          },
        });
        Swal.fire(response.data.message, "Vehicule Ajouté!", "success");
        that.spanView = false;
      } catch (error) {
        that.spanView = false;
        that.authError = error;
        Swal.fire({
          title: "Oops...",
          text: "Erreur lors de l'ajout du véhicule",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
    submit(){
      this.showModal = true;
    }
  },
};
</script>